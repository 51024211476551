import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { Button, IconButton } from "../components";
import Title from "../components/Title";
import Table from "../components/Table";
import Firebase from "../config/firebase";
import { AuthenticatedUserContext } from "../navigation/AuthenticatedUserProvider";
import axios from "axios";

const auth = Firebase.auth();

const API_URL = "";

export default ({ navigation }) => {
  const { user } = useContext(AuthenticatedUserContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const req = await axios.get(`https://raccoon-dev.onebid.group/rank`);
      if (!req.err) {
        console.log(data);
        setData(req.data.map(e => {
          if(e.nickName === 'null'){
            e.nickName = "Nieznany zbieracz"
          }

          if(e.nickName === 'undefined'){
            return null
          }

          return e
        }).filter(e => e !== null));
      }
    };
    if (data.length === 0) {
      fetch();
    }
  }, [data]);

  return (
    <View style={styles.container}>
      <Title title={"Ranking"} navigation={navigation} />
      {data.length !== 0 ? (
        <ScrollView>
          {data.sort((a,b) => b.points - a.points).map((e) => (
            <Button
              emojiIcon={String(e.points || 0)}
              title={e.nickName}
              titleColor="#000"
              backgroundColor="#fff"
              containerStyle={styles.button}
              onPress={() => {}}
              key={e.id}
            />
          ))}
        </ScrollView>
      ) : (
        <View>
          <Text>Brak danych do wyświetlenia</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#e93b81",
    paddingTop: 50,
    paddingHorizontal: 12,
  },
  button: {
    height: Dimensions.get("window").height * 0.2,
    borderRadius: 20,
    marginBottom: 20,
  },
});
