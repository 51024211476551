import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { useRoute } from "@react-navigation/native";

import { Button, IconButton } from "../components";
import Firebase from "../config/firebase";
import { AuthenticatedUserContext } from "../navigation/AuthenticatedUserProvider";

const auth = Firebase.auth();

const MenuStates = {
  Home: 1,
  BackHome: 2,
};

export default ({ navigation }) => {
  const { user } = useContext(AuthenticatedUserContext);
  const [nickName, setNickName] = useState(null);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  const changeName = () => {};

  useEffect(() => {
    let nickName = user.displayName;
    console.log(nickName)
    console.log(user)
    if (!nickName) {
      setNickName("[Ustaw ksywe]");
    } else {
      setNickName(nickName);
    }
  }, [user]);

  return (
    <View style={styles.container}>
      <StatusBar style="dark-content" />

      <View style={styles.row}>
        <Text style={styles.title} onClick={() => changeName()}>
          Cześć {user.displayName ? user.displayName : "nieznajomy"}!👋🏻
        </Text>
        <IconButton
          name="logout"
          size={24}
          color="#fff"
          onPress={handleSignOut}
        />
      </View>

      <View style={styles.column}>
        <Button
          emojiIcon="🤔"
          title="Jak to robić?"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => navigation.navigate("HowTo")}
        />
        <Button
          emojiIcon="🔫"
          title="Dodaj nowy"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => navigation.navigate("Capture")}
        />
        <Button
          emojiIcon="👑"
          title="Ranking"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => navigation.navigate("Rank")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#e93b81",
    paddingTop: 50,
    paddingHorizontal: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: Dimensions.get("window").height * 0.1,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    color: "#fff",
  },
  text: {
    fontSize: 16,
    fontWeight: "normal",
    color: "#fff",
  },
  column: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    height: Dimensions.get("window").height * 0.75,
  },
  button: {
    height: "32%",
    borderRadius: 20,
  },
});
