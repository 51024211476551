import { StatusBar } from "expo-status-bar";
import React, { useContext } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";

import { Button, IconButton } from "../components";
import Title from "../components/Title";
import Firebase from "../config/firebase";
import { AuthenticatedUserContext } from "../navigation/AuthenticatedUserProvider";

const auth = Firebase.auth();

export default ({ navigation }) => {
  const { user } = useContext(AuthenticatedUserContext);

  return (
    <View style={styles.container}>
      <Title title="Jak to robić? 🙈" navigation={navigation} />

      <ScrollView>
        <Button
          emojiIcon="🏄🏻"
          title="Przygotuj jednolite tło (np. kartke lub karton)"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => {}}
        />
        <Button
          emojiIcon="💡"
          title="Zapewnij oświetlenie"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => {}}
        />
        <Button
          emojiIcon="🐒"
          title="Ulóz smiec na podlozu"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => {}}
        />
        <Button
          emojiIcon="🔫"
          title="Zrob ładną fote"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => {}}
        />
        <Button
          emojiIcon="🏌🏻"
          title="Wybierz kategorie smiecia"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => {}}
        />
        <Button
          emojiIcon="🎖"
          title="Zbierz punkty!"
          titleColor="#000"
          backgroundColor="#fff"
          containerStyle={styles.button}
          onPress={() => {}}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#e93b81",
    paddingTop: 50,
    paddingHorizontal: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    color: "#fff",
  },
  text: {
    fontSize: 16,
    fontWeight: "normal",
    color: "#fff",
  },
  column: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    height: Dimensions.get("window").height * 0.75,
  },
  button: {
    height: Dimensions.get("window").height * 0.2,
    borderRadius: 20,
    marginBottom: 20,
  },
});
