import { StatusBar } from "expo-status-bar";
import React, { useContext, useState } from "react";
import {Platform} from "react-native"
import axios from "axios";
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";

import Title from "../components/Title";
import { Button, IconButton } from "../components";
import Firebase from "../config/firebase";
import { AuthenticatedUserContext } from "../navigation/AuthenticatedUserProvider";

import Camera from "../components/Camera";
import { ScrollView } from "react-native-gesture-handler";

const auth = Firebase.auth();

export default ({ navigation }) => {
  const { user } = useContext(AuthenticatedUserContext);

  const [picture, setPicture] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [category, setCategory] = useState(null);

  const handlePicture = (picture) => {
    setPicture(
      `${Platform.OS !== "web" ? "data:image/png;base64," : ""}${
        picture.base64
      }`
    );
  };

  const handleUpload = async (category) => {
    if (picture) {
      console.log('kurwa')
      const req = await axios.post(`https://raccoon-dev.onebid.group/upload`, {
        image: picture.split(",")[1],
        category: category,
        userId: user.uid,
        nickName: user.displayName
      });

      if (req) {
        if (req.data) {
          console.log(req.data);
        }
      }

      setCategory(category);
    }
  };

  /*
    TODO
    Axios get categories
    Axios upload
  */
  const data = [
    { icon: "📃", title: "papier", id: "1" },
    { icon: "⚙️ ", title: "tworzywo sztuczne", id: "2" },
    { icon: "🍾 ", title: "szkło", id: "0" },
    { icon: "🤢 ", title: "bio", id: "3" },
    { icon: "🤫 ", title: "zmieszane", id: "4" },
  ];

  if (picture) {
    if (confirm) {
      if (category) {
        return (
          <View style={styles.container}>
            <Title title="Jesteś gość(-ówa) 🤙🏻 " navigation={navigation} />
            <View>
              <Text
                style={{
                  color: "#fff",
                  fontSize: 120,
                  textAlign: "center",
                  marginTop: "25%",
                }}
              >
                🥳
              </Text>
              <Text
                style={{
                  color: "#fff",
                  fontSize: 30,
                  textAlign: "center",
                  marginTop: "20%",
                  marginBottom: "10%",
                }}
              >
                Zdobywasz 100pkt!
              </Text>
              <Button
                onPress={() => navigation.navigate("Home")}
                emojiIcon="👊🏻"
                titleColor="black"
                backgroundColor="#fff"
              ></Button>
            </View>
          </View>
        );
      } else {
        return (
          <View style={styles.container}>
            <Title title="Co to za 💩?" navigation={navigation} />
            <ScrollView>
              {data.map((e) => (
                <Button
                  emojiIcon={e.icon}
                  title={e.title}
                  titleColor="#000"
                  backgroundColor="#fff"
                  containerStyle={styles.button}
                  onPress={() => handleUpload(e.id)}
                  key={e.id}
                />
              ))}
            </ScrollView>
          </View>
        );
      }
    } else {
      return (
        <View style={styles.container}>
          <Title title="Jest git? 💪🏻" navigation={navigation} />
          <Image
            style={{
              width: Dimensions.get("window").width * 0.9,
              height: Dimensions.get("window").height * 0.7,
              alignSelf: "center",
              borderRadius: 20,
              backgroundColor: "#fff",
            }}
            source={{ uri: picture }}
          />
          <View style={styles.row}>
            <Button
              onPress={() => setConfirm(true)}
              title="TAK 😎"
              width="45%"
              backgroundColor="#fff"
              titleColor="green"
            />
            <Button
              onPress={() => setPicture(null)}
              title="NIE 🤕"
              width="45%"
              backgroundColor="#fff"
              titleColor="red"
            />
          </View>
        </View>
      );
    }
  } else {
    return <Camera handlePicture={handlePicture} />;
  }
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#e93b81",
    paddingTop: 50,
    paddingHorizontal: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    color: "#fff",
  },
  text: {
    fontSize: 16,
    fontWeight: "normal",
    color: "#fff",
  },
  column: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    height: Dimensions.get("window").height * 0.75,
  },
  button: {
    height: Dimensions.get("window").height * 0.2,
    borderRadius: 20,
    marginBottom: 20,
  },
});
