import React, { useContext, useEffect, useState } from "react";
import { Dimensions, View, Text } from "react-native";
import { IconButton } from ".";

export default ({ title, navigation }) => {
  return (
    <View style={{ flexDirection: "row", margin: 10 }}>
      <View style={{width: 60}}>
        <IconButton
          name="back"
          size={36}
          color="#fff"
          onPress={() => navigation.navigate("Home")}
        />
      </View>
      <Text style={{ fontSize: 36, color: "#fff" }}>{title}</Text>
    </View>
  );
};
