import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from '../screens/HomeScreen';
import HowToScreen from '../screens/HowToScreen';
import CaptureScreen from '../screens/CaptureScreen';
import RankScreen from '../screens/RankScreen';

const Stack = createStackNavigator();

export default function HomeStack() {
  return (
    <Stack.Navigator headerMode='none'>
      <Stack.Screen name='Home' component={HomeScreen} />
      <Stack.Screen name='HowTo' component={HowToScreen} />
      <Stack.Screen name='Capture' component={CaptureScreen} />
      <Stack.Screen name='Rank' component={RankScreen} />
    </Stack.Navigator>
  );
}
