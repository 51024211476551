import { StatusBar } from "expo-status-bar";
import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";

import { Button, IconButton } from "../components";
import Firebase from "../config/firebase";
import { AuthenticatedUserContext } from "../navigation/AuthenticatedUserProvider";

import { Camera } from "expo-camera";

export default ({ handlePicture }) => {
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const [camera, setCamera] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);

  const askForPermission = async () => {
    const { status } = await Camera.requestPermissionsAsync();
    setHasCameraPermission(status === "granted");
  };

  const takePicture = async () => {
    if (camera) {
      const options = { quality: 1, base64: true };
      const data = await camera.takePictureAsync(options);
      handlePicture(data);
    }
  };

  useEffect(() => {
    askForPermission();
  }, []);

  if (hasCameraPermission === true) {
    return (
      <View style={{ flex: 1 }}>
        <Camera
          style={{ flex: 1 }}
          type={type}
          ref={(ref) => {
            setCamera(ref);
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "transparent",
              justifyContent: "flex-end",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <TouchableOpacity
                style={{ alignSelf: "center" }}
                onPress={async () => {
                  takePicture();
                }}
              >
                <View
                  style={{
                    borderWidth: 2,
                    borderRadius: 50,
                    borderColor: "red",
                    height: 80,
                    width: 80,
                    display: "flex",
                    marginBottom: 40,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      borderWidth: 2,
                      borderRadius: 50,
                      borderColor: "red",
                      height: 60,
                      width: 60,
                      backgroundColor: "red",
                    }}
                  ></View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Camera>
      </View>
    );
  } else {
    return (
      <SafeAreaView tyle={styles.container}>
        <Text>Brak dostępu do kamery</Text>
        <Button title="Nadaj uprawnienia" onPress={() => askForPermission()} />
      </SafeAreaView>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#e93b81",
    paddingTop: 50,
    paddingHorizontal: 12,
  },
});
